exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ar-404-js": () => import("./../../../src/pages/ar/404.js" /* webpackChunkName: "component---src-pages-ar-404-js" */),
  "component---src-pages-ar-agegate-js": () => import("./../../../src/pages/ar/agegate.js" /* webpackChunkName: "component---src-pages-ar-agegate-js" */),
  "component---src-pages-ar-promotion-close-js": () => import("./../../../src/pages/ar/promotion-close.js" /* webpackChunkName: "component---src-pages-ar-promotion-close-js" */),
  "component---src-pages-en-404-js": () => import("./../../../src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-pages-en-agegate-js": () => import("./../../../src/pages/en/agegate.js" /* webpackChunkName: "component---src-pages-en-agegate-js" */),
  "component---src-pages-en-stores-js": () => import("./../../../src/pages/en/stores.js" /* webpackChunkName: "component---src-pages-en-stores-js" */),
  "component---src-pages-es-agegate-js": () => import("./../../../src/pages/es/agegate.js" /* webpackChunkName: "component---src-pages-es-agegate-js" */),
  "component---src-pages-es-promotion-close-js": () => import("./../../../src/pages/es/promotion-close.js" /* webpackChunkName: "component---src-pages-es-promotion-close-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pt-agegate-js": () => import("./../../../src/pages/pt/agegate.js" /* webpackChunkName: "component---src-pages-pt-agegate-js" */)
}

